export default class DashboardFetchers {

    static indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

    static db = this.initializeDB();

    static async initializeDB() {
        return new Promise((resolve, reject) => {
            const request = this.indexedDB.open('dashboard-data', 1);

            request.onupgradeneeded = event => {
                const db = event.target.result;
                const keys = Object.keys(this.COUNTER_LISTS);
                keys.forEach(key => {
                    if (!db.objectStoreNames.contains(key)) {
                        db.createObjectStore(key, { keyPath: 'id' });
                    }
                });
            };

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    static get DEFAULT_RELOAD_INTERVAL() {
        return {
            hours: 0,
            minutes: 3,
            seconds: 30,
        };
    }

    static get COUNTER_LISTS() {
        return {
            pre_visites: {
                url: `${process.env.MIX_API_URL}/dashboard/pre_visites`,
                table: '#pre_visites-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
            raccordements: {
                url: `${process.env.MIX_API_URL}/dashboard/raccordements`,
                table: '#raccordements-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 30,
                },
                lastIssueColumn: true,
            },
            post_production: {
                url: `${process.env.MIX_API_URL}/dashboard/post_production`,
                table: '#post_production-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
            support_be: {
                url: `${process.env.MIX_API_URL}/dashboard/support_be`,
                table: '#support-be-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
            ot_prise_contact_raccordement: {
                url: `${process.env.MIX_API_URL}/dashboard/ot_prise_contact_raccordement`,
                table: '#ot_prise_contact_raccordement-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
            enquete_de_satisfaction: {
                url: `${process.env.MIX_API_URL}/dashboard/enquete_de_satisfaction`,
                table: '#enquete_de_satisfaction-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
            sav_gta: {
                url: `${process.env.MIX_API_URL}/dashboard/sav_gta`,
                table: '#sav_gta-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10,
                }
            },
        };
    }

    static getReloadInterval(interval) {
        return (interval.hours ?? 0) * 3600000 + (interval.minutes ?? 0) * 60000 + (interval.seconds ?? 0) * 1000;
    }

    static async getLocalItem(key) {
        const { id } = window.selectedVue;
        const db = await this.db;
        const transaction = db.transaction([key], 'readonly');
        const store = transaction.objectStore(key);

        return new Promise((resolve, reject) => {
            const request = store.get(id);
            request.onsuccess = () => {
                resolve(request.result || { id, data: {}, timestamp: 0 });
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    static async setLocalItem(key, data) {
        const { id } = window.selectedVue;
        const db = await this.db;
        const transaction = db.transaction([key], 'readwrite');
        const store = transaction.objectStore(key);
        const item = { id, data: data, timestamp: Date.now() };
        await store.put(item);
        this.refreshTable(key, data);
    }

    static async fetchRemoteItem(key) {
        const url = this.COUNTER_LISTS[key].url;
        try {
            const response = await $.ajax({ url });
            await this.setLocalItem(key, response.data || {});
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    static async getItem(key) {
        const localItem = await this.getLocalItem(key);
        const reloadInterval = this.COUNTER_LISTS[key]?.reloadInterval || this.DEFAULT_RELOAD_INTERVAL;
        const isTimeExceeded = Date.now() - localItem.timestamp > this.getReloadInterval(reloadInterval);

        if (isTimeExceeded) {
            return await this.fetchRemoteItem(key);
        } else {
            await this.refreshTable(key, localItem.data);
        }
    }

    static async refreshTable(key, data = null) {
        const { table, lastIssueColumn } = this.COUNTER_LISTS[key];
        const tbody = $(table);
        tbody.empty();
        data = data || (await this.getLocalItem(key)).data || {};

        Object.entries(data).forEach(([id, item]) => {
            const { url, label, count, count_last_issue } = item;

            let row = `<tr class="cursor-pointer" data-href="${url}" title="${url}">
                        <td class="w-50"><span class="font-w600">${label}</span></td>
                        <td class="text-center ${!lastIssueColumn ? 'w-50' : ''}"><span class="badge badge-pill bg-primary font-w600 text-white">${count}</span></td>`;

            if (lastIssueColumn) {
                row += `<td class="text-center">${count_last_issue ? `<span class="badge badge-pill bg-primary font-w600 text-white">${count_last_issue}</span>` : ''}</td>`;
            }
            row += '</tr>';

            tbody.append(row);
        });
    }
}